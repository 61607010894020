<template>
  <h1 class="text-white p-5 text-xl font-semibold">Assessment</h1>
  <div class="bg-white min-h-screen m-5">
    <TraningSelectUser
      class="p-5"
      v-if="profiles.length > 0"
      :users="profiles"
      @changeUser="filterUsername"
    />
    <div class="flex flex-col items-center pt-6 justify-center">
      <img
        src="@/assets/icons/download-icon.svg"
        alt="Download icon"
        width="131"
      />
      <p class="mt-2 font-semibold text-lg">Download</p>
    </div>
    <div class="p-10">
      <div>
        <div class="overflow-scroll md:overflow-auto">
          <Table :columns="columns">
            <template v-slot:tbody-rows>
              <tr
                class="pl-2"
                v-for="assessment in assessments"
                :key="assessment.id"
              >
                <td class="pl-3">1</td>
                <td class="pl-3">{{ profileSelected.username }}</td>
                <td class="pl-3">
                  {{ formatDate(assessment.create_at) }}
                </td>
                <td class="pl-3">
                  <img
                    src="@/assets/icons/download-assessment-icon.svg"
                    width="24"
                    class="ml-6"
                    @click="getAssesmentLink(assessment.file_name)"
                    alt="Download icon"
                  />
                </td>
              </tr>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import {
  getUserProfiles,
  getUserAssessmentReports,
  getAssesmentReportByFileName,
} from "@/services/user/user.js";
import TraningSelectUser from "@/components/traning/TraningSelectUser.vue";
import Table from "@/components/general/Table.vue";

const store = useStore();

const profiles = ref([]);
const profileSelected = ref(null);
const assessments = ref(null);
const columns = ["Assessment #", "Username", "Date", "Download"];

const user = computed(() => store.getters["user/getUserData"]);

onMounted(() => {
  getProfiles();
});

async function userAssessments(id) {
  const { data } = await getUserAssessmentReports(id);
  assessments.value = data;
}

async function getProfiles() {
  const { data } = await getUserProfiles(user.value.id);
  profiles.value = data;
  profileSelected.value = profiles.value[0];
  await userAssessments(profileSelected.value.id);
}

async function getAssesmentLink(name) {
  const { data } = await getAssesmentReportByFileName(profileSelected, name);

  window.open(data.link);
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}

async function filterUsername(user) {
  profileSelected.value = user;
  await userAssessments(user.id);
}
</script>
